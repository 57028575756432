<script setup lang="ts">
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';

    const emits = defineEmits<{
        redirect: [];
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="not-found-container">
        <span class="pi pi-box icon" />
        <h2 class="heading">{{ t('NOT_FOUND.TITLE') }}</h2>
        <p>{{ t('NOT_FOUND.DESCRIPTION_PART1') }}</p>
        <p>{{ t('NOT_FOUND.DESCRIPTION_PART2') }}</p>
        <Button icon="pi pi-percentage" :label="t('NOT_FOUND.CTA_BUTTON')" @click="emits('redirect')" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .heading {
        font-weight: 700;
        margin-bottom: main.$spacing-6;
    }

    .icon {
        font-size: 36px;
        color: main.$color-secondary;
        margin-bottom: main.$spacing-6;
    }

    .not-found-container {
        margin: 0 auto;
        background: main.$color-white;
        padding: main.$spacing-6;
        text-align: center;
        max-width: 588px;
    }
</style>
